import { StaticImage } from 'gatsby-plugin-image';
import content from 'content/content.json';
import Layout from 'components/Layout';
import React from 'react';
import seo from 'config/seo.json';
export default class Contact extends React.Component {
	render() {
		return (
			<Layout className="bg-light-gray" title={seo.contact.title} description={seo.contact.description}>
				<section className="grid grid-cols-1 lg:grid-cols-2 gap-32 max-w-7xl mx-auto px-8 lg:px-16 pt-12 pb-24">
					<header>
						<h1 className="font-semibold mb-8 text-5xl">
							{content.pages.contact.title}
						</h1>
						<p className="mb-8">{content.pages.contact.content}</p>
					</header>
					<figure className="hidden lg:flex items-center row-start-1 lg:col-start-2">
						<StaticImage
							src="../assets/images/hero-contact.png"
							alt="Two people sitting down with one pointing to the others laptop screen"
						/>
					</figure>
				</section>
			</Layout>
		);
	}
}
